var $ = require("jquery");
var infowindow = '';
var styles = [{ featureType: "administrative", elementType: "labels.text.fill", stylers: [{ color: "#444444" }] }, { featureType: "administrative", elementType: "labels.text.stroke", stylers: [{ color: "#ffffff" }] }, { featureType: "administrative.locality", elementType: "geometry", stylers: [{ color: "#fbcb3f" }] }, { featureType: "administrative.neighborhood", elementType: "geometry", stylers: [{ color: "#fbcb3f" }] }, { featureType: "administrative.land_parcel", elementType: "geometry", stylers: [{ color: "#fbcb3f" }] }, { featureType: "landscape", elementType: "all", stylers: [{ color: "#f2f2f2" }] }, { featureType: "poi", elementType: "all", stylers: [{ visibility: "off" }] }, { featureType: "road", elementType: "all", stylers: [{ saturation: -100 }, { lightness: 45 }] }, { featureType: "road.highway", elementType: "all", stylers: [{ visibility: "simplified" }] }, { featureType: "road.arterial", elementType: "labels.icon", stylers: [{ visibility: "off" }] }, { featureType: "transit", elementType: "all", stylers: [{ visibility: "off" }] }, { featureType: "water", elementType: "all", stylers: [{ color: "#46bcec" }, { visibility: "on" }] }, { featureType: "water", elementType: "geometry", stylers: [{ color: "#04b6b8" }] }, { featureType: "water", elementType: "labels.text.fill", stylers: [{ saturation: "100" }, { lightness: "100" }] }];

var lang = $('html')[0].lang;
var popup_btn_text = '';
if (lang == 'fr_ca' || lang == 'fr' || lang == 'fr_fr' || lang == 'fr-FR' || lang == 'fr-fr') {
    popup_btn_text = 'Voir sur Google Map';
} else {
    popup_btn_text = 'See on Google Map';
}

import { MarkerClusterer, GridAlgorithm } from "@googlemaps/markerclusterer";

let map;

window.initialize = function() {
    var markers = [];
    var pin = [];
    // var markerClusterer = '';
    var marker_icon = $('.c-map-google').data('pin');
    var marker_cluster_icon = $('.c-map-google').data('cluster');
    var infowindow = new google.maps.InfoWindow();
	var bounds = new google.maps.LatLngBounds();
	var zoom = 12;

	// var styledMap = new google.maps.StyledMapType(styles, {name: "custom-map"});


	var options = {
        zoom      : zoom,
		styles: styles,
       // center    : latLng,
        scrollwheel: false,
        mapTypeId : google.maps.MapTypeId.ROADMAP, // Type de carte, différentes valeurs possible HYBRID, ROADMAP, SATELLITE, TERRAIN
        maxZoom   : 12,
        mapTypeControl: false,
        streetViewControl: false,
        // mapTypeControlOptions: {
        //     mapTypeIds: [google.maps.MapTypeId.ROADMAP, 'map_style']
        // },
        zoomControl: true,
    };

	
    // var data = {
    //     "lat": $('.pin').attr("data-lat"),
    //     "lng": $('.pin').attr("data-lng"),
    //     "pin": $('.pin').attr("data-pin")
    // };

    // pin.push(data);

    if($('.pin').length) {
    	$('.pin').each(function(){
			var data = {
	        	"lat": $(this).attr("data-lat"),
	        	"lng": $(this).attr("data-lng"),
	        	"pin": $(this).attr("data-pin"),
	        	"title": $(this).attr("data-title"),
	        	"address": $(this).attr("data-address"),
	        	"phone": $(this).attr("data-phone"),
	        	"website_link": $(this).attr("data-website_link"),
	        	"website_text": $(this).attr("data-website_text")
    		};

    		pin.push(data);
    	});
    }
    	

    if(pin.length) {
    	var lat = pin[0]['lat'];
    	var latLng = new google.maps.LatLng(pin[0]['lat'], pin[0]['lng']);
    	var marker_pin = pin[0]['pin'];
		options.center = latLng;
	}

	map = new google.maps.Map(document.getElementById('google'), options);
	//  map.mapTypes.set('map_style', styledMap);
	//  map.setMapTypeId('map_style');

	if(pin.length) {
        var infoWindowContent = [];
		for (var i = 0; i < pin.length; i++) {
			var marker = new google.maps.Marker({
				map: map,
				position: new google.maps.LatLng(pin[i]['lat'], pin[i]['lng']),
                icon: marker_icon,
                url: pin[i].url,
            });
            var infoWindowContent_item = '';
            infoWindowContent_item += '<div class="map_window">';
                if(pin[i]['title']) infoWindowContent_item += '<h4 class="map_window_title uk-h5">' + pin[i]['title'] + ' </h4>';
                infoWindowContent_item += '<div class="map_window_body">';
                    if(pin[i]['address']) infoWindowContent_item += '<div class="map_window_address">' + pin[i]['address'] + '</div>';
                    if(pin[i]['phone']) infoWindowContent_item += '<div class="map_window_phone">' + pin[i]['phone'] + '</div>';
                    if(pin[i]['website_link']) infoWindowContent_item += '<a class="map_window_website" target="_blank" href="' + pin[i]['website_link'] + '">' +  pin[i]['website_text'] + '</a> ';
                    if(pin[i]['lat'] && pin[i]['lng']) infoWindowContent_item += '<div class="vy_button"><a class="uk-button uk-button-small uk-button-primary" target="_blank" href="https://www.google.com/maps/dir/Current+Location/' + pin[i]['lat'] + ',%20' + pin[i]['lng'] + '">' + popup_btn_text + '</a></div>';
                infoWindowContent_item += '</div>';
            infoWindowContent_item += '</div>';
            infoWindowContent_item = [infoWindowContent_item];
            infoWindowContent.push(infoWindowContent_item);
            markers.push(marker);

            bounds.extend(marker.position);

            google.maps.event.addListener(marker, 'click', (function(marker, i) {
                return function() {
                    infowindow.setContent(infoWindowContent[i][0]);
                    infowindow.open(map, marker);
                    //window.open(map, marker);
                    // window.open('https://www.google.com/maps/dir/Current+Location/'+pin[i]['lat']+',%20'+pin[i]['lng']);
                } 
            })(marker, i));
              
            
		}
	}
    /*google.maps.event.addListener(marker, 'click', function() {
      window.open('https://www.google.com/maps/dir/Current+Location/'+pin[0]['lat']+',%20'+pin[0]['lng']);
    });*/

    map.fitBounds(bounds);

    const renderer = {
        render({ count, position }) {
            return new google.maps.Marker({
                label: { text: String(count), color: "white", fontSize: "20px" },
                icon: {
                    url: marker_cluster_icon,
                    scaledSize: new google.maps.Size(40, 40),
                },
                position,
                zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
            });
        }
    }

    const gridOptions = {
        gridSize: 40,
        maxDistance: 400,
        maxZoom: 12,
        viewportPadding: 60
    };

    new MarkerClusterer({
        map,
        markers,
        renderer,
        algorithm: new GridAlgorithm(gridOptions)
    });
    
}

function loadScript() {
  var script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = 'https://maps.googleapis.com/maps/api/js?v=3.exp' +
      '&callback=initialize&key=AIzaSyDCmFgc3_pL0rHuf9QQ1u2hAEGbP1QzxTw';
  
  document.body.appendChild(script);
}
if (document.getElementById("google")) {
    window.onload = loadScript;
}

